{
  "name": "sembot-angular",
  "version": "3.20.1",
  "versionFilePath": "assets/version.json",
  "scripts": {
    "build:analyze": "ng build --stats-json && webpack-bundle-analyzer dist/stats.json",
    "build:beta": "npm run build:beta-standalone && npm run build:beta-plugin",
    "build:beta-plugin": "ng build -c=beta-plugin --base-href /front/",
    "build:beta-standalone": "ng build -c=beta && node scripts/version.js",
    "build:dev1": "ng build -c=dev1 && node scripts/version.js",
    "build:dev2": "ng build -c=dev2 && node scripts/version.js",
    "build:dev3": "ng build -c=dev3 && node scripts/version.js",
    "build:dev4": "ng build -c=dev4 && node scripts/version.js",
    "build:any": "node scripts/build.js",
    "build:production": "npm run build:production-standalone && npm run build:production-plugin",
    "build:production-plugin": "ng build --configuration production-plugin --base-href /front/",
    "build:production-standalone": "ng build --configuration production && node scripts/version.js",
    "build:whitelabel": "ng build -c=whitelabel && node scripts/version.js",
    "compodoc": "npx compodoc -p tsconfig.doc.json --disableDependencies --minimal --coverageTest",
    "lib": "node scripts/lib-watcher.js",
    "lint:styles": "npx stylelint \"**/*.scss\"",
    "ng": "ng",
    "start": "ng serve",
    "start:beta": "ng serve -c=beta",
    "start:beta-plugin": "ng serve -c=beta-plugin",
    "start:dev1": "ng serve -c=dev1",
    "start:dev2": "ng serve -c=dev2",
    "start:dev3": "ng serve -c=dev3",
    "start:dev4": "ng serve -c=dev4",
    "start:dev5": "ng serve -c=dev5",
    "start:dev6": "ng serve -c=dev6",
    "start:dev7": "ng serve -c=dev7",
    "start:dev8": "ng serve -c=dev8",
    "start:local": "ng serve -c=local",
    "start:plugin-beta": "ng serve -c=beta-plugin",
    "start:production": "ng serve -c=production",
    "start:whitelabel": "ng serve -c=whitelabel",
    "test": "ng test",
    "test:ci": "ng test --browsers=ChromeHeadless --watch=false",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org sentry --project sembot-frontend ./dist && sentry-cli --url $SENTRY_URL sourcemaps upload --org sentry --project sembot-frontend ./dist",
    "translation:copy": "node scripts/translations/copy-translations-to-new-file.js",
    "translation:check": "node scripts/translations/find-missing-keys.js",
    "translation:sort-keys": "node scripts/translations/sort-keys-in-translations.js && npx prettier --write \"src/**/*.json\"",
    "translation:validate-keys": "node scripts/translations/validation-translation-keys.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/cdk": "^18.1.1",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/material": "^18.1.1",
    "@angular/material-moment-adapter": "^18.1.1",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@danielmoncada/angular-datetime-picker": "^18.0.0",
    "@ngrx/signals": "^18.0.2",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@sentry/angular": "^8.36.0",
    "@sentry/cli": "^2.38.2",
    "@sentry/replay": "^7.116.0",
    "@shopify/app-bridge": "^3.2.5",
    "@shopify/app-bridge-utils": "^3.2.5",
    "@stripe/stripe-js": "^4.1.0",
    "angular-google-tag-manager": "~1.9.0",
    "angular-notifier": "^14.0.0",
    "apexcharts": "^3.54.1",
    "compare-versions": "^3.6.0",
    "deepmerge": "^4.2.2",
    "event-source-polyfill": "^1.0.24",
    "export-to-csv": "^0.2.1",
    "laravel-echo": "^1.19.0",
    "lodash": "^4.17.20",
    "marked": "^12.0.2",
    "moment": "^2.24.0",
    "ng-apexcharts": "^1.12.0",
    "ng-dynamic-component": "^10.7.0",
    "ng-recaptcha": "^13.2.1",
    "ngx-clipboard": "^16.0.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-editor": "^17.5.4",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-mat-select-search": "^7.0.6",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-stripe": "^18.1.0",
    "posthog-js": "^1.219.3",
    "prosemirror-autocomplete": "^0.4.3",
    "prosemirror-state": "^1.4.3",
    "prosemirror-view": "^1.33.9",
    "pusher-js": "^8.4.0",
    "rxjs": "~7.8.1",
    "socket.io-client": "^4.8.0",
    "tslib": "^2.0.0",
    "uuid": "^9.0.1",
    "webpack-bundle-analyzer": "^4.4.1",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@angular/language-service": "^18.1.0",
    "@eslint/js": "^9.17.0",
    "@types/eslint-config-prettier": "^6.11.3",
    "@types/event-source-polyfill": "^1.0.5",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/lodash": "^4.14.168",
    "@types/node": "^18.19.50",
    "@types/stripe-checkout": "^1.0.3",
    "@types/stripe-v3": "^3.1.24",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "^8.19.1",
    "@typescript-eslint/parser": "^8.19.1",
    "@typescript-eslint/utils": "^8.19.1",
    "angular-eslint": "^19.0.2",
    "autoprefixer": "^10.4.19",
    "eslint": "^9.17.0",
    "eslint-config-prettier": "^7.2.0",
    "eslint-plugin-prettier": "^5.2.1",
    "globals": "^15.14.0",
    "husky": "^4.3.8",
    "jasmine-core": "~3.8",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.3.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "laravel-echo": "^2.0.2",
    "lint-staged": "^10.5.3",
    "postcss": "^8.4.38",
    "prettier": "^3.3.3",
    "pusher-js": "^8.4.0",
    "stylelint": "^16.8.1",
    "stylelint-config-recommended": "^14.0.1",
    "stylelint-config-recommended-scss": "^14.1.0",
    "stylelint-scss": "^6.4.1",
    "tailwindcss": "^3.4.4",
    "ts-node": "~7.0.0",
    "typescript": "~5.4.5",
    "typescript-eslint": "^8.19.1"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged --relative && npm run translation:sort-keys && npm run translation:check && npm run translation:validate-keys"
    }
  },
  "lint-staged": {
    "*.{js,ts,scss}": [
      "prettier -w"
    ],
    "*.{js,ts}": [
      "eslint"
    ],
    "**/*.scss": [
      "npm run lint:styles"
    ]
  },
  "overrides": {
    "angular-notifier": {
      "@angular/common": "^18.1.0",
      "@angular/core": "^18.1.0"
    },
    "angular-google-tag-manager": {
      "@angular/common": "^18.1.0",
      "@angular/compiler": "^18.1.0"
    },
    "ng-recaptcha": {
      "@angular/core": "^18.1.0"
    }
  }
}
